<template>
    <div :class="sliderContainerClass">
        <div class="swiper" :class="sliderMainClass">
            <div class="swiper-wrapper" :class="sliderWrapperClass">
                <div class="swiper-slide" :class="sliderSlideClass" v-for="post in posts" :key="post.id">
                    <div class="product" :class="productExtraClass">
                        <a :href="post.uri" class="product__img">
                            <div class="product__img__frame">
                                <img src="/images/prod-frame3.jpg" alt="" class="product__img__bg">
                                <img :data-src="post.imgSrc" src="/images/prod-frame3.jpg" alt="" class="product__img__main lazyload">
                            </div>
                            <div class="product__img__band" v-if="post.exclusive">
                                <img data-src="/images/prod-band.svg" src="/images/prod-band-dummy.jpg" alt="" class="lazyload">
                            </div>
                        </a>
                        <span class="product__category" v-if="post.category && !post.categoryLink">{{post.category}}</span>
                        <a :href="post.categoryLink" class="product__category product__category--link" :class="categoryColor(post.categoryColor)" v-if="post.category && post.categoryLink">{{post.category}}</a>
                        <div class="product__main">
                            <ul class="product__tags">
                                <li class="product__tags__item" v-for="tag in post.tags" :key="tag">{{tag}}</li>
                            </ul>
                            <a :href="post.uri" class="product__name">{{post.name}}</a>
                            <a :href="post.companyLink" class="product__company" v-if="post.companyLink">{{post.company}}</a>
                            <span class="product__company" v-else></span>
                            <p class="product__price">
                                <span class="product__price__main" :class="{'product__price__main--stockout' : post.stockout}">{{post.price}}</span>
                                <span class="product__price__original" v-if="post.priceOriginal && !post.stockout">{{post.priceOriginal}}</span>
                                <span class="product__price__stockout" v-if="post.stockout">在庫切れ</span>
                            </p>
                            <p class="product__discount" v-html="post.discount" v-if="post.discount"></p>
                            <button v-if="post.btn && post.btn.type === 'video' && post.btn.url" class="product__btn product__btn--video" @click="movieModalOpen(post.id, post.btn.url)"><span>視聴する</span></button>
                            <a href="#" v-if="post.btn && post.btn.type === 'audio'" :data-target="'modal' + post.id" class="product__btn product__btn--audio open-modal-audio"><span>再生する</span></a>
                            <a :href="post.btn.url" v-if="post.btn && post.btn.type === 'book'" target="_blank" class="product__btn product__btn--book"><span>読む</span></a>
                            <a :href="post.btn.url" v-if="post.btn && post.btn.type === 'download'" class="product__btn product__btn--download"><span>ダウンロード</span></a>
                            <a :href="post.btn.url" v-if="post.btn && post.btn.type === 'link'" class="product__btn product__btn--detail"><span>詳細を見る</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-button-prev" :class="sliderPrevClass"></div>
        <div class="swiper-button-next" :class="sliderNextClass"></div>
    </div>
    <template v-for="post in posts" :key="post.id">
        <transition v-if="post.btn && post.btn.type === 'video' && post.btn.url" name="fade">
            <div class="modal-overlay modal-video modal-open" v-show="post.videoModalOpen" style="display: none">
                <div class="modal-close modal-video-close" :id="'modal-video-close-' + post.id" @click="movieModalClose(post.id, post.btn.url)"></div>
                <div class="modal-container" @click="movieModalClose(post.id, post.btn.url)">
                    <div class="modal" @click.stop>
                        <div class="modal-movie">
                            <div class="modal-movie__frame">
                                <div class="mv__video video-wrapper" :id="'video-wrapper-' + post.id"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div :id="'modal' + post.id" class="modal-audio-overlay d-none" v-if="post.btn && post.btn.type === 'audio'">
            <div class="modal-audio-close"></div>
            <div class="modal-audio-container">
                <div class="modal">
                    <div class="modal-audio modal-content">
                        <div :id="'audioPlayer' + post.id" :data-playlist="post.btn.src" class="audioPlayer">
                            <div class="audioPlayer__main">
                                <div class="audioPlayer__main__left">
                                    <div class="audioPlayer__thumb">
                                        <img :src="post.imgSrc" alt="">
                                    </div>
                                </div>
                                <div class="audioPlayer__main__right">
                                    <div class="audioPlayer__ttl">{{post.name}}</div>
                                    <div class="swiper playlist">
                                        <div class="swiper-wrapper list"></div>
                                        <div class="swiper-scrollbar"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="audioPlayer__control">
                                <div class="audioPlayer__control__top">
                                    <div class="audioPlayer__control__holder">
                                        <div class="waveform"></div>
                                        <div class="progress"></div>
                                    </div>
                                    <div class="audioPlayer__control__timer">
                                        <div class="timer">0:00</div>
                                        <div class="duration">0:00</div>
                                    </div>
                                </div>
                                <div class="audioPlayer__control__bottom">
                                    <span class="track"></span>
                                    <div class="controlsInner">
                                        <div class="btn prevBtn"></div>
                                        <div class="controlsInner__center">
                                            <div class="loading"></div>
                                            <div class="btn playBtn"></div>
                                            <div class="btn pauseBtn"></div>
                                        </div>
                                        <div class="btn nextBtn"></div>
                                    </div>
                                    <div class="volume">
                                        <div class="volumeBtn"></div>
                                        <div class="bar barFull"></div>
                                        <div class="bar barEmpty"></div>
                                        <div class="sliderBtn"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

import { debounce } from 'throttle-debounce';

import { backfaceFixed } from '../plugins/backfaceFixed';

export default {
    props: {
        posts: {
            type: Array,
            required: true
        },
        sliderName: {
            type: String,
            required: true
        },
        sliderClass: {
            type: String,
            required: true
        },
        productExtraClass: String,
        maxSlidesPerView: {
            type: Number,
            default: 6
        },
        slideWidth: {
            type: Number,
            default: 162
        },
        doujin: Boolean,
    },
    data() {
        return {
            slider: null
        }
    },
    computed: {
        sliderContainerClass(){
            return `${this.sliderClass}-container`;
        },
        sliderMainClass(){
            return `${this.sliderName} ${this.sliderClass}`;
        },
        sliderWrapperClass(){
            return `${this.sliderClass}__wrapper`;
        },
        sliderSlideClass(){
            return `${this.sliderClass}__slide`;
        },
        sliderPrevClass(){
            return `${this.sliderName}__button-prev ${this.sliderClass}__button-prev`;
        },
        sliderNextClass(){
            return `${this.sliderName}__button-next ${this.sliderClass}__button-next`;
        },
    },
    methods: {
        movieModalOpen(id, src){
            videoLoad(id, src);
            this.posts[id - 1].videoModalOpen = true;
            backfaceFixed(true);
        },
        movieModalClose(id, src){
            modalClose(id, src);
            this.posts[id - 1].videoModalOpen = false;
            setTimeout(() => {
                backfaceFixed(false);
            }, 300);
        },
        sliderInit(){
            this.slider = new Swiper(`.${this.sliderName}`, {
                spaceBetween: 10,
                slidesPerView: "auto",
                breakpoints: {
                    576: {
                        slidesPerView: 3,
                        spaceBetween: 19,
                    },
                    992: {
                        slidesPerView: this.sliderSlidesPerView(this.getSliderWidth()),
                        spaceBetween: 38,
                    }
                },
                navigation: {
                    prevEl: `.${this.sliderName}__button-prev`,
                    nextEl: `.${this.sliderName}__button-next`,
                },
            });
        },
        getSliderWidth(){
            return document.querySelector(`.${this.sliderName}`).offsetWidth;
        },
        sliderSlidesPerView(sliderWidth){
            const maxSlidesPerView = this.maxSlidesPerView;
            const slideWidth = this.slideWidth;
            let slideCount = 1;
            while (sliderWidth - ((slideWidth * slideCount) + 38 * (slideCount - 1)) >= slideWidth + 38) {
                slideCount++;
                if(slideCount === maxSlidesPerView){
                    break;
                }
            }
            return slideCount;
        },
        overwriteSliderSlidesPerView(){
            const sliderWidth = this.getSliderWidth();
            this.slider.params.slidesPerView = this.sliderSlidesPerView(sliderWidth);
            this.slider.update();
        },
        categoryColor(color){
            return 'product__category--' + color;
        },
        matchHeight(el){
            const targets = document.querySelector('.' + this.sliderName).querySelectorAll(el);
            let maxHeight = 0;
            for (let index = 0; index < targets.length; index++) {
                const target = targets[index];
                target.style.height = 'auto';
                if(target.clientHeight > maxHeight){
                    maxHeight = target.clientHeight;
                }
            }
            for (let index = 0; index < targets.length; index++) {
                const target = targets[index];
                target.style.height = maxHeight + 'px';
            }
        }
    },
    mounted(){
        const _this = this;
        _this.sliderInit();
        window.addEventListener('resize', debounce(250, function(){
            if(window.matchMedia('(min-width: 992px)').matches){
                _this.overwriteSliderSlidesPerView();
            }
        }));
        this.matchHeight('.product__name');
        this.matchHeight('.product__tags');
        this.matchHeight('.product__company');
        this.matchHeight('.product__price');
    },
    updated(){
        this.matchHeight('.product__name');
        this.matchHeight('.product__tags');
        this.matchHeight('.product__company');
        this.matchHeight('.product__price');
    }
}
</script>
