import { backfaceFixed } from './plugins/backfaceFixed';
import { slideUp, slideDown } from './plugins/slideAnime';
import smoothScroll from './modules/smoothScroll';
import topSlider from './modules/topSlider';
import 'lazysizes';
import { createApp } from 'vue';
import audioPlayer from './modules/audioPlayer';

if(!document.getElementById('mypage-app') && !document.getElementById('article-app') && document.querySelector('.audioPlayer')){
    document.querySelectorAll('.audioPlayer').forEach(audio => {
        const targerId = audio.getAttribute('id');
        const playlist = audiolist[audio.dataset.playlist];
        audioPlayer(targerId, playlist);
    });
}
smoothScroll();
topSlider();

const searchFormToggler = document.getElementById('header__nav__btn');
const searchForm = document.getElementById('header__search-form');

if(searchFormToggler && searchForm){
    searchFormToggler.addEventListener('click', () => {
        searchFormToggler.classList.toggle('header__nav__btn--active');
        searchForm.classList.toggle('header__search-form--active');
        if(searchForm.classList.contains('header__search-form--active')){
            slideDown(searchForm);
        }else{
            slideUp(searchForm);
        }
        searchFormToggler.style.pointerEvents = 'none';
        setTimeout(() => {
            searchFormToggler.style.pointerEvents = 'auto';
        }, 300);
    });
    const searchFormSelect = searchForm.querySelector('.header__search-form__category');
    if(searchFormSelect){
        const searchFormSelectSpan = searchFormSelect.children[0];
        const searchFormSelectOriginal = searchFormSelect.children[1];
        searchFormSelectOriginal.addEventListener('change', () => {
            const options = searchFormSelectOriginal.options;
            let slectedOption;
            for (let index = 0; index < options.length; index++) {
                if(options[index].selected){
                    slectedOption = options[index];
                }
            }
            const slectedOptionText = slectedOption.textContent;
            searchFormSelectSpan.textContent = slectedOptionText;
        });
    }
}

const navToggler = document.getElementById('bottom-nav__btn');
const nav = document.getElementById('side');

if(navToggler && nav){
    navToggler.addEventListener('click', () => {
        nav.classList.toggle('side--active');
        if(nav.classList.contains('side--active')){
            slideDown(nav);
            setTimeout(() => {
                backfaceFixed(true);
            }, 300);
        }else{
            backfaceFixed(false);
            slideUp(nav);
        }
        navToggler.style.pointerEvents = 'none';
        setTimeout(() => {
            navToggler.style.pointerEvents = 'auto';
        }, 300);
    });
    window.addEventListener('resize', () => {
        if(window.matchMedia('(min-width: 768px)').matches){
            if(nav.classList.contains('side--active')){
                backfaceFixed(false);
                nav.style.display = 'none';
                nav.classList.remove('side--active');
            }
        }
    });
}

const inputAry = document.getElementsByName('method');
if(inputAry){
    inputAry.forEach(element => {
        if(element.dataset.inputSlide){
            if(element.checked){
                const targetSlide = document.querySelector(element.dataset.inputSlide);
                if(targetSlide){
                    slideDown(targetSlide);
                }
            }
            element.addEventListener('change', ()=>{
                const targetSlide = document.querySelector(element.dataset.inputSlide);
                const otherSlidesInput = document.querySelectorAll('[data-input-slide]:not(:checked)');
                if(element.checked){
                    slideDown(targetSlide);
                    otherSlidesInput.forEach(element => {
                        const target = document.querySelector(element.dataset.inputSlide);
                        if(target){
                            slideUp(target);
                            const targetInput = target.querySelector('input[type="radio"]:checked');
                            if(targetInput){
                                targetInput.checked = false;
                            }
                        }
                    });
                }
            });
        }else{
            element.addEventListener('change', ()=>{
                const otherSlidesInput = document.querySelectorAll('[data-input-slide]:not(:checked)');
                if(element.checked){
                    otherSlidesInput.forEach(element => {
                        const target = document.querySelector(element.dataset.inputSlide);
                        if(target){
                            slideUp(target);
                            const targetInput = target.querySelector('input[type="radio"]:checked');
                            if(targetInput){
                                targetInput.checked = false;
                            }
                        }
                    });
                }
            });
        }
    });
}
