import { createApp } from 'vue';
const axios = require('axios');


if(document.getElementById('ranking-app')){
    const RankingApp = {
        data(){
            return {
                posts: posts,
                sortRanking: 'popularity',
                postsPerView: 15,
                additionalFirstPostId: 16,
                postsTotal: 100,
                viewBtn: true,
                loading: false,
                loaded: false,
                error: false,
                additional: true,
                additionalPostsError: false
            }
        },
        methods: {
            changeSortRanking(category){
                this.sortRanking = category;
                this.loaded = false;
                this.loading = true;
                this.getRankingPostsData(category);
            },
            getRankingPostsData(category){
                this.additionalPostsError = false;
                this.additional = false;
                let params = new URLSearchParams();
                params.append('sort_category', category);
                axios
                .post(ajaxRequestUri, params)
                .then(response => {
                    const responseObj = response.data;
                    this.posts = responseObj;
                    if(this.posts.length < this.postsTotal){
                        this.viewBtn = true;
                        this.additionalFirstPostId = 16;
                    }
                    setTimeout(() => {
                        this.loading = false;
                        this.loaded = true;
                        this.error = false;
                    }, 300);
                })
                .catch(error => {
                    console.log(error);
                    setTimeout(() => {
                        this.loading = false;
                        this.error = true;
                        this.viewBtn = false;
                    }, 300);
                })
                .finally(() => {

                });
            },
            getAdditionalPostsData(category, first_id, quantity){
                this.error = false;
                this.additional = true;
                let params = new URLSearchParams();
                params.append('sort_category', category);
                params.append('first_id', first_id);
                params.append('quantity', quantity);
                axios
                .post(ajaxRequestUri, params)
                .then(response => {
                    this.additionalPostsError = false;
                    const responseObj = response.data;
                    this.posts = [...this.posts, ...responseObj];
                    this.additionalFirstPostId = this.additionalFirstPostId + this.postsPerView;
                    if(this.posts.length === this.postsTotal){
                        this.viewBtn = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.additionalPostsError = true;
                })
                .finally(() => {

                });
            },
            matchHeight(el){
                const targets = document.querySelector('.ranking__container').querySelectorAll(el);
                let maxHeight = 0;
                for (let index = 0; index < targets.length; index++) {
                    const target = targets[index];
                    target.style.height = 'auto';
                    if(target.clientHeight > maxHeight){
                        maxHeight = target.clientHeight;
                    }
                }
                for (let index = 0; index < targets.length; index++) {
                    const target = targets[index];
                    target.style.height = maxHeight + 'px';
                }
            }
        },
        beforeUpdate(){
            if(!this.additional){
                const loadedImages = this.$el.querySelectorAll('.lazyloaded');
                for (let index = 0; index < loadedImages.length; index++) {
                    const element = loadedImages[index];
                    element.className = 'product__img__main lazyload';
                    // if(element.classList.contains('product__img__main')){
                    //     element.className = 'product__img__main lazyload';
                    // }else{
                    //     element.className = 'lazyload';
                    // }
                }
            }
        },
        mounted(){
            this.matchHeight('.product__name');
            this.matchHeight('.product__tags');
            this.matchHeight('.product__company');
            this.matchHeight('.product__price');
        },
        updated(){
            this.matchHeight('.product__name');
            this.matchHeight('.product__tags');
            this.matchHeight('.product__company');
            this.matchHeight('.product__price');
        }
    };
    createApp(RankingApp).mount('#ranking-app');
}
