import { debounce } from 'throttle-debounce';
import { createApp } from 'vue';
import { backfaceFixed } from '../plugins/backfaceFixed';

if(document.getElementById('new__container')){

    const newApp = createApp({
        data(){
            return {
                posts: posts,
            }
        },
        methods: {
            movieModalOpen(id, src){
                videoLoad(id, src);
                this.posts[id - 1].modalOpen = true;
                backfaceFixed(true);
            },
            movieModalClose(id, src){
                modalClose(id, src);
                this.posts[id - 1].modalOpen = false;
                setTimeout(() => {
                    backfaceFixed(false);
                }, 300);
            },
            changeItemWidth(){
                if(window.matchMedia('(min-width: 1409px)').matches){
                    const containerWidth = document.getElementById('new__container').offsetWidth;
                    const itemWidth = 187;
                    let itemCount = 1;
                    while (containerWidth - (itemWidth * itemCount) >= itemWidth){
                        itemCount++;
                    }
                    const newItemWidth = (100 / itemCount).toFixed(5);
                    const newItems = document.querySelectorAll('.new__item');
                    for (let index = 0; index < newItems.length; index++) {
                        const element = newItems[index];
                        element.style.width = 'calc(' + newItemWidth + '% + 1px)';
                    }
                }else{
                    if(document.querySelector('.new__item').style.width !== ''){
                        const newItems = document.querySelectorAll('.new__item');
                        for (let index = 0; index < newItems.length; index++) {
                            const element = newItems[index];
                            element.style.width = '';
                        }
                    }
                }
            },
            matchHeight(el){
                const targets = document.querySelector('.new__container').querySelectorAll(el);
                let maxHeight = 0;
                for (let index = 0; index < targets.length; index++) {
                    const target = targets[index];
                    target.style.height = 'auto';
                    if(target.clientHeight > maxHeight){
                        maxHeight = target.clientHeight;
                    }
                }
                for (let index = 0; index < targets.length; index++) {
                    const target = targets[index];
                    target.style.height = maxHeight + 'px';
                }
            }
        },
        mounted(){
            this.changeItemWidth();
            window.addEventListener('resize', debounce(200, this.changeItemWidth));
            this.matchHeight('.product__name');
            this.matchHeight('.product__tags');
            this.matchHeight('.product__company');
            this.matchHeight('.product__price');
        }
    });
    newApp.mount('#new__container');

}

if(document.getElementById('new__table')){
    const targets = document.querySelectorAll('.new__table__ttl__link');
    for (let index = 0; index < targets.length; index++) {
        const target = targets[index];
        const bal = target.nextElementSibling;
        const changeBalPos = (e) => {
            var mouseX = e.clientX;
            var mouseY = e.clientY;
            bal.style.left = mouseX - 75 + 'px';
            bal.style.top = mouseY - 200 + 'px';
        }
        target.addEventListener('mouseover', () => {
            bal.classList.add('new__table__bal--active');
            document.addEventListener("mousemove" , changeBalPos);
        });
        target.addEventListener('mouseout', () => {
            bal.classList.remove('new__table__bal--active');
            document.removeEventListener("mousemove" , changeBalPos);
        });
    }
}
