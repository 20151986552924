<template>
    <div :class="[sliderContainerClass, {loading : loading}, {loaded : loaded}, {error, error}]">
        <div class="swiper" :class="sliderMainClass">
            <div class="swiper-wrapper" :class="sliderWrapperClass">
                <div class="swiper-slide" :class="sliderSlideClass" v-for="post in activePosts" :key="post.id">
                    <div class="product" :class="productExtraClass">
                        <p class="product__rank" v-if="rank">
                            <img :src="'/images/prod-rank' + post.id + '.svg'" :alt="post.id" v-if="post.id < 4">
                            <span v-else>{{post.id}}</span>
                        </p>
                        <a :href="post.uri" class="product__img">
                            <div class="product__img__frame">
                                <img src="/images/prod-frame3.jpg" alt="" class="product__img__bg">
                                <img :data-src="post.imgSrc" src="/images/prod-frame3.jpg" alt="" class="product__img__main lazyload">
                            </div>
                            <div class="product__img__band" v-if="post.exclusive">
                                <img data-src="/images/prod-band.svg" src="/images/prod-band-dummy.jpg" alt="" class="lazyload">
                            </div>
                        </a>
                        <span class="product__category" v-if="post.category && !post.categoryLink">{{post.category}}</span>
                        <a :href="post.categoryLink" class="product__category product__category--link" :class="categoryColor(post.categoryColor)" v-if="post.category && post.categoryLink">{{post.category}}</a>
                        <div class="product__main">
                            <ul class="product__tags">
                                <li class="product__tags__item" v-for="tag in post.tags" :key="tag">{{tag}}</li>
                            </ul>
                            <a :href="post.uri" class="product__name">{{post.name}}</a>
                            <a :href="post.companyLink" class="product__company" v-if="post.companyLink">{{post.company}}</a>
                            <span class="product__company" v-else></span>
                            <p class="product__price">
                                <span class="product__price__main" :class="{'product__price__main--stockout' : post.stockout}">{{post.price}}</span>
                                <span class="product__price__original" v-if="post.priceOriginal && !post.stockout">{{post.priceOriginal}}</span>
                                <span class="product__price__stockout" v-if="post.stockout">在庫切れ</span>
                            </p>
                            <p class="product__discount" v-html="post.discount" v-if="post.discount"></p>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide" :class="[sliderSlideClass, sliderSlideClass + '--next']" v-if="touchdevice && ( posts.length > maxMobileSlides ) && viewAllBtn">
                    <span class="product product--next" @click="viewAll = !viewAll" v-if="!viewAll">
                        <img data-src="/images/prod-next1.svg" src="/images/prod-dummy1.jpg" alt="" class="lazyload" v-if="!doujin">
                        <img data-src="/images/prod-next2.svg" src="/images/prod-dummy2.jpg" alt="" class="lazyload" v-else>
                    </span>
                </div>
            </div>
        </div>
        <div class="swiper-button-prev" :class="sliderPrevClass"></div>
        <div class="swiper-button-next" :class="sliderNextClass"></div>
    </div>
</template>

<script>
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

import { debounce } from 'throttle-debounce';

export default {
    props: {
        posts: {
            type: Array,
            required: true
        },
        sliderName: {
            type: String,
            required: true
        },
        sliderClass: {
            type: String,
            required: true
        },
        productExtraClass: String,
        maxMobileSlides: {
            type: Number,
            required: true
        },
        categoryLink: String,
        maxSlidesPerView: {
            type: Number,
            default: 6
        },
        slideWidth: {
            type: Number,
            default: 162
        },
        viewAllBtn: Boolean,
        doujin: Boolean,
        rank: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            slider: null,
            removedSlides: [],
            touchdevice: false,
            viewAll: false,
            loading: false,
            loaded: false,
            error: false,
            firstPaint: true
        }
    },
    computed: {
        activePosts(){
            if(this.touchdevice){
                if(this.viewAll){
                    return this.posts;
                }
                const newPosts = this.posts.filter((val, index) => {
                    return (index < this.maxMobileSlides);
                });
                return newPosts;
            }
            return this.posts;
        },
        sliderContainerClass(){
            return `${this.sliderClass}-container`;
        },
        sliderMainClass(){
            return `${this.sliderName} ${this.sliderClass}`;
        },
        sliderWrapperClass(){
            return `${this.sliderClass}__wrapper`;
        },
        sliderSlideClass(){
            return `${this.sliderClass}__slide`;
        },
        sliderPrevClass(){
            return `${this.sliderName}__button-prev ${this.sliderClass}__button-prev`;
        },
        sliderNextClass(){
            return `${this.sliderName}__button-next ${this.sliderClass}__button-next`;
        }
    },
    methods: {
        sliderInit(){
            this.slider = new Swiper(`.${this.sliderName}`, {
                spaceBetween: 10,
                slidesPerView: "auto",
                breakpoints: {
                    576: {
                        slidesPerView: 3,
                        spaceBetween: 19,
                    },
                    992: {
                        slidesPerView: this.sliderSlidesPerView(this.getSliderWidth()),
                        spaceBetween: 38,
                    }
                },
                navigation: {
                    prevEl: `.${this.sliderName}__button-prev`,
                    nextEl: `.${this.sliderName}__button-next`,
                },
            });
        },
        sliderDestroy(){
            this.slider.destroy(true, true);
            this.slider = null;
        },
        sliderUpdate(){
            this.slider.update();
        },
        sliderResetPosition(){
            this.slider.slideTo(0, 0);
        },
        getSliderWidth(){
            return document.querySelector(`.${this.sliderName}`).offsetWidth;
        },
        sliderSlidesPerView(sliderWidth){
            const maxSlidesPerView = this.maxSlidesPerView;
            const slideWidth = this.slideWidth;
            let slideCount = 1;
            while (sliderWidth - ((slideWidth * slideCount) + 38 * (slideCount - 1)) >= slideWidth + 38) {
                slideCount++;
                if(slideCount === maxSlidesPerView){
                    break;
                }
            }
            return slideCount;
        },
        overwriteSliderSlidesPerView(){
            const sliderWidth = this.getSliderWidth();
            this.slider.params.slidesPerView = this.sliderSlidesPerView(sliderWidth);
            this.slider.update();
        },
        setSlider(){
            const _this = this;
            if(window.matchMedia('(min-width: 576px)').matches){
                _this.sliderInit();
                _this.touchdevice = false;
            }else{
                _this.touchdevice = true;
            }
            window.addEventListener('resize', debounce(250, function(){
                if(window.matchMedia('(min-width: 576px)').matches){
                    if(_this.touchdevice){
                        _this.touchdevice = false;
                        setTimeout(function(){
                            _this.sliderInit();
                        }, 200);
                    }
                }else{
                    if(!_this.touchdevice){
                        _this.touchdevice = true;
                        _this.sliderDestroy();
                    }
                }
                if(window.matchMedia('(min-width: 992px)').matches){
                    if(_this.slider){
                        _this.overwriteSliderSlidesPerView();
                    }
                }
            }));
        },
        categoryColor(color){
            return 'product__category--' + color;
        },
        matchHeight(el){
            const targets = document.querySelector('.' + this.sliderName).querySelectorAll(el);
            let maxHeight = 0;
            for (let index = 0; index < targets.length; index++) {
                const target = targets[index];
                target.style.height = 'auto';
                if(target.clientHeight > maxHeight){
                    maxHeight = target.clientHeight;
                }
            }
            for (let index = 0; index < targets.length; index++) {
                const target = targets[index];
                target.style.height = maxHeight + 'px';
            }
        }
    },
    mounted(){
        this.setSlider();
        this.matchHeight('.product__name');
        this.matchHeight('.product__tags');
        this.matchHeight('.product__company');
        this.matchHeight('.product__price');
    },
    beforeUpdate(){
        if(!this.touchdevice){
            const loadedImages = this.$el.querySelectorAll('.lazyloaded');
            for (let index = 0; index < loadedImages.length; index++) {
                const element = loadedImages[index];
                element.className = 'product__img__main lazyload';
            }
        }
    },
    updated(){
        this.firstPaint = false;
        this.matchHeight('.product__name');
        this.matchHeight('.product__tags');
        this.matchHeight('.product__company');
        this.matchHeight('.product__price');
    }
}
</script>
