import { createApp } from 'vue';
import Slider from '../components/Slider.vue';
import SliderRanking from '../components/SliderRanking.vue';
import SliderDoujin from '../components/SliderDoujin.vue';
const axios = require('axios');

const getCategoryIndexApp = (categoryIndex) => {
    return {
        data(){
            return {
                posts_ranking: posts_ranking,
                posts_pickUp: posts_pickUp,
                posts_recommend: posts_recommend,
                posts_sale: posts_sale,
                sortRanking: 'popularity'
            }
        },
        components: {
            Slider,
            SliderRanking
        },
        methods: {
            changeSortRanking(category){
                this.sortRanking = category;
                this.$refs.sliderRanking.loaded = false;
                this.$refs.sliderRanking.loading = true;
                this.getRankingPostsData(category);
            },
            getRankingPostsData(category){
                let params = new URLSearchParams();
                params.append('sort_category', category);
                axios
                .post(ajaxRequestUri, params)
                .then(response => {
                    const responseObj = response.data;
                    this.posts_ranking = responseObj;
                    setTimeout(() => {
                        this.$refs.sliderRanking.loading = false;
                        this.$refs.sliderRanking.loaded = true;
                        this.$refs.sliderRanking.error = false;
                    }, 300);
                })
                .catch(error => {
                    console.log(error);
                    setTimeout(() => {
                        this.$refs.sliderRanking.loading = false;
                        this.$refs.sliderRanking.error = true;
                    }, 300);
                })
                .finally(() => {
                    if(this.$refs.sliderRanking.slider){
                        this.$refs.sliderRanking.sliderUpdate();
                        this.$refs.sliderRanking.sliderResetPosition();
                    }
                });
            },
            matchHeight(el){
                const targets = document.querySelector('.new-arrival').querySelectorAll(el);
                let maxHeight = 0;
                for (let index = 0; index < targets.length; index++) {
                    const target = targets[index];
                    target.style.height = 'auto';
                    if(target.clientHeight > maxHeight){
                        maxHeight = target.clientHeight;
                    }
                }
                for (let index = 0; index < targets.length; index++) {
                    const target = targets[index];
                    target.style.height = maxHeight + 'px';
                }
            }
        },
        mounted() {
            this.matchHeight('.product__name');
            this.matchHeight('.product__tags');
            this.matchHeight('.product__company');
            this.matchHeight('.product__price');
        }
    }
}

if(document.getElementById('movies-app')){
    const MoviesApp = getCategoryIndexApp('movies');
    createApp(MoviesApp).mount('#movies-app');
}

if(document.getElementById('games-app')){
    const GamesApp = getCategoryIndexApp('games');
    createApp(GamesApp).mount('#games-app');
}

if(document.getElementById('books-app')){
    const BooksApp = getCategoryIndexApp('books');
    createApp(BooksApp).mount('#books-app');
}

if(document.getElementById('ec-app')){
    const EcApp = getCategoryIndexApp('ec');
    createApp(EcApp).mount('#ec-app');
}

if(document.getElementById('doujin-app')){
    const DoujinApp = {
        data(){
            return {
                posts_ranking: posts_ranking,
                posts_categoryRanking: posts_categoryRanking,
                posts_pickUp: posts_pickUp,
                posts_recommend: posts_recommend,
                posts_sale: posts_sale,
                sortRanking: 'popularity',
                sortCategoryRanking: 'popularity'
            }
        },
        components: {
            Slider,
            SliderRanking,
            SliderDoujin
        },
        methods: {
            changeSortRanking(category){
                this.sortRanking = category;
                this.$refs.sliderRanking.loaded = false;
                this.$refs.sliderRanking.loading = true;
                this.getRankingPostsData(category);
            },
            getRankingPostsData(category){
                let params = new URLSearchParams();
                params.append('sort_category', category);
                axios
                .post(ajaxRequestUri, params)
                .then(response => {
                    const responseObj = response.data;
                    this.posts_ranking = responseObj;
                    setTimeout(() => {
                        this.$refs.sliderRanking.loading = false;
                        this.$refs.sliderRanking.loaded = true;
                        this.$refs.sliderRanking.error = false;
                    }, 300);
                })
                .catch(error => {
                    console.log(error);
                    setTimeout(() => {
                        this.$refs.sliderRanking.loading = false;
                        this.$refs.sliderRanking.error = true;
                    }, 300);
                })
                .finally(() => {
                    if(this.$refs.sliderRanking.slider){
                        this.$refs.sliderRanking.sliderUpdate();
                        this.$refs.sliderRanking.sliderResetPosition();
                    }
                });
            },
            changeSortCategoryRanking(category){
                this.sortCategoryRanking = category;
                this.$refs.sliderDoujin.loaded = false;
                this.$refs.sliderDoujin.loading = true;
                this.getCategoryRankingPostsData(category);
            },
            getCategoryRankingPostsData(category){
                let params = new URLSearchParams();
                params.append('sort_category', category);
                axios
                .post(ajaxRequestUri_category, params)
                .then(response => {
                    const responseObj = response.data;
                    setTimeout(() => {
                        this.posts_categoryRanking = responseObj;
                        this.$refs.sliderDoujin.loading = false;
                        this.$refs.sliderDoujin.loaded = true;
                        this.$refs.sliderDoujin.error = false;
                    }, 300);
                })
                .catch(error => {
                    console.log(error);
                    setTimeout(() => {
                        this.$refs.sliderDoujin.loading = false;
                        this.$refs.sliderDoujin.error = true;
                    }, 300);
                })
                .finally(() => {
                    if(this.$refs.sliderDoujin.slider){
                        this.$refs.sliderDoujin.sliderResetPosition();
                        this.$refs.sliderDoujin.sliderUpdate();
                    }
                });
            },
            matchHeight(){
                const targets = document.querySelectorAll('.new-arrival__item .product__tags');
                let maxHeight = 0;
                for (let index = 0; index < targets.length; index++) {
                    const target = targets[index];
                    target.style.height = 'auto';
                    if(target.clientHeight > maxHeight){
                        maxHeight = target.clientHeight;
                    }
                }
                for (let index = 0; index < targets.length; index++) {
                    const target = targets[index];
                    target.style.height = maxHeight + 'px';
                }
            }
        },
        mounted() {
            this.matchHeight();
        }
    };
    createApp(DoujinApp).mount('#doujin-app');
}
