// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

export default () => {

  const mainSlider = new Swiper(".main__slider", {
    loop: true,
    spaceBetween: 10,
    loopAdditionalSlides: 2,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".main__slider__pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '.main__slider__button-next',
      prevEl: '.main__slider__button-prev',
    },
  });


  const subSlider = new Swiper(".main__sub-slider", {
    spaceBetween: 10,
    slidesPerView: 2,
    slidesPerGroup: 2,
    breakpoints: {
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1400: {
        slidesPerView: 5,
        slidesPerGroup: 5,
      },
    },
    navigation: {
      nextEl: '.main__sub-slider__button-next',
      prevEl: '.main__sub-slider__button-prev',
    },
  });

}
