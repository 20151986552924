// スムーススクロール

import SmoothScroll from 'smooth-scroll';

export default () => {
    const scroll = new SmoothScroll('a[href*="#"]', {
        ignore: '[data-scroll-ignore]',
        header: null,
        speed: 500,
        speedAsDuration: true,
        offset: 0,
        easing: 'easeInOutCubic',
        updateURL: false,
        popstate: false,
    });
}
