import { createApp } from 'vue';
import Slider from '../components/Slider.vue';
import SliderRanking from '../components/SliderRanking.vue';

if(document.getElementById('home-app')){
    const homeApp = createApp({
        data(){
            return {
                posts_recommend: posts_recommend,
                posts_sale: posts_sale,
                posts_video: posts_video,
                posts_game: posts_game,
                posts_eBook: posts_eBook,
                posts_mailOrder: posts_mailOrder,
                posts_doujin: posts_doujin
            }
        },
        components: {
            Slider,
            SliderRanking
        }
    });
    homeApp.mount('#home-app');
}
