import { createApp } from 'vue';
import { debounce } from 'throttle-debounce';
import Slider from '../components/Slider.vue';
import { backfaceFixed } from '../plugins/backfaceFixed';
import audioPlayer from '../modules/audioPlayer';

if(document.getElementById('mypage-app')){

    const mypageApp = createApp({
        data(){
            return {
                posts_purchased: posts_purchased,
                posts_favorite: posts_favorite,
                posts_cart: posts_cart,
            }
        },
        components: {
            Slider
        },
        mounted(){
            if(document.querySelector('.audioPlayer')){
                document.querySelectorAll('.audioPlayer').forEach(audio => {
                    console.log(audio.getAttribute('id'));
                    const targerId = audio.getAttribute('id');
                    const playlist = audiolist[audio.dataset.playlist];
                    audioPlayer(targerId, playlist);
                });
            }
        }
    });
    mypageApp.mount('#mypage-app');

}

if(document.getElementById('mypage__review__tab')){

    const changeItemWidth1 = () => {
        if(window.matchMedia('(min-width: 1409px)').matches){
            const containerWidth = document.getElementById('mypage__review__list1').offsetWidth;
            const itemWidth = 187;
            let itemCount = 1;
            while (containerWidth - (itemWidth * itemCount) >= itemWidth){
                itemCount++;
            }
            const newItemWidth = (100 / itemCount).toFixed(5);
            const newItems = document.getElementById('mypage__review__list1').querySelectorAll('.mypage__review__list__item');
            for (let index = 0; index < newItems.length; index++) {
                const element = newItems[index];
                element.style.width = newItemWidth + '%';
            }
        }else{
            if(document.querySelector('.mypage__review__list__item').style.width !== ''){
                const newItems = document.getElementById('mypage__review__list1').querySelectorAll('.mypage__review__list__item');
                for (let index = 0; index < newItems.length; index++) {
                    const element = newItems[index];
                    element.style.width = '';
                }
            }
        }
    }
    const changeItemWidth2 = () => {
        if(window.matchMedia('(min-width: 1409px)').matches){
            const containerWidth = document.getElementById('mypage__review__list2').offsetWidth;
            const itemWidth = 187;
            let itemCount = 1;
            while (containerWidth - (itemWidth * itemCount) >= itemWidth){
                itemCount++;
            }
            const newItemWidth = (100 / itemCount).toFixed(5);
            const newItems = document.getElementById('mypage__review__list2').querySelectorAll('.mypage__review__list__item');
            for (let index = 0; index < newItems.length; index++) {
                const element = newItems[index];
                element.style.width = newItemWidth + '%';
            }
        }else{
            if(document.querySelector('.mypage__review__list__item').style.width !== ''){
                const newItems = document.getElementById('mypage__review__list2').querySelectorAll('.mypage__review__list__item');
                for (let index = 0; index < newItems.length; index++) {
                    const element = newItems[index];
                    element.style.width = '';
                }
            }
        }
    }

    changeItemWidth1();
    changeItemWidth2();
    window.addEventListener('resize', debounce(200, changeItemWidth1));
    window.addEventListener('resize', debounce(200, changeItemWidth2));

}

if(document.getElementById('mypage__favorite__list')){

    const changeItemWidth = () => {
        if(window.matchMedia('(min-width: 1415px)').matches){
            const containerWidth = document.getElementById('mypage__favorite__list').offsetWidth;
            const itemWidth = 187;
            let itemCount = 1;
            while (containerWidth - (itemWidth * itemCount) >= itemWidth){
                itemCount++;
            }
            const newItemWidth = (100 / itemCount).toFixed(5);
            const newItems = document.querySelectorAll('.mypage__favorite__list__item');
            for (let index = 0; index < newItems.length; index++) {
                const element = newItems[index];
                element.style.width = 'calc(' + newItemWidth + '% + 1px)';
            }
        }else{
            if(document.querySelector('.mypage__favorite__list__item').style.width !== ''){
                const newItems = document.querySelectorAll('.mypage__favorite__list__item');
                for (let index = 0; index < newItems.length; index++) {
                    const element = newItems[index];
                    element.style.width = '';
                }
            }
        }
    }
    changeItemWidth();
    window.addEventListener('resize', debounce(200, changeItemWidth));

}


if(document.getElementById('mypage__review__write__star')){

    const btns = document.querySelectorAll('.mypage__review__write__star__btn');
    const input = document.getElementById('mypage__review__write__star__value');

    for (let index = 0; index < btns.length; index++) {
        const btn = btns[index];
        btn.addEventListener('click', () => {
            const btnVal = btn.dataset.value;
            const setStar = (val) => {
                input.value = val;
                // console.log(input.value);
                for (let index = 0; index < btns.length; index++) {
                    const btn = btns[index];
                    if(btn.classList.contains('mypage__review__write__star__btn--active')){
                        btn.classList.remove('mypage__review__write__star__btn--active');
                    }
                }
                for (let index = 0; index < val; index++) {
                    const btn = btns[index];
                    btn.classList.add('mypage__review__write__star__btn--active');
                }
            };
            switch (btnVal) {
                case '1':
                    setStar(1);
                    break;
                case '2':
                    setStar(2);
                    break;
                case '3':
                    setStar(3);
                    break;
                case '4':
                    setStar(4);
                    break;
                case '5':
                    setStar(5);
                    break;
                default:
                    break;
            }
        });
    }

}

const modalBtns = document.querySelectorAll('.open-modal');
modalBtns.forEach(btn => {
    const targetModalId = btn.dataset.target;
    const modal = document.getElementById(targetModalId);
    modal.classList.remove('d-none');
    btn.addEventListener('click', (e) => {
        e.preventDefault();
        modal.classList.add('modal-open');
        backfaceFixed(true);
    });
    const close = modal.querySelector('.modal-close');
    const container = modal.querySelector('.modal-container');
    const content = modal.querySelector('.modal-content');
    close.addEventListener('click', () => {
        modal.classList.remove('modal-open');
        backfaceFixed(false);
    });
    container.addEventListener('click', () => {
        modal.classList.remove('modal-open');
        backfaceFixed(false);
    });
    content.addEventListener('click', (e) => {
        e.stopPropagation();
    });
});

let bodyFixed = false;
const modalAudioBtns = document.querySelectorAll('.open-modal-audio');
modalAudioBtns.forEach(btn => {
    const targetModalId = btn.dataset.target;
    const modalAudio = document.getElementById(targetModalId);
    const audioPlayerId = modalAudio.querySelector('.audioPlayer').getAttribute('id');
    modalAudio.classList.remove('d-none');
    btn.addEventListener('click', (e) => {
        e.preventDefault();
        modalAudio.classList.add('modal-open');
        if(window.matchMedia('(max-width: 767px)').matches){
            backfaceFixed(true);
            bodyFixed = true;
        }
        document.querySelectorAll('.mypage__purchased__btn').forEach(btn => {
            btn.classList.add('inactive');
        });
        document.querySelectorAll('.product__btn').forEach(btn => {
            btn.classList.add('inactive');
        });
    });
    const close = modalAudio.querySelector('.modal-audio-close');
    const content = modalAudio.querySelector('.modal-content');
    close.addEventListener('click', () => {
        modalAudio.classList.remove('modal-open');
        if(bodyFixed){
            backfaceFixed(false);
        }
        document.querySelectorAll('.mypage__purchased__btn').forEach(btn => {
            btn.classList.remove('inactive');
        });
        document.querySelectorAll('.product__btn').forEach(btn => {
            btn.classList.remove('inactive');
        });
        if(window[audioPlayerId].playstate){
            window[audioPlayerId].pause();
        }

    });
    content.addEventListener('click', (e) => {
        e.stopPropagation();
    });
});
