<template>
    <div :class="[sliderContainerClass, {loading : loading}, {loaded : loaded}, {error, error}]">
        <div class="swiper" :class="sliderMainClass">
            <div class="swiper-wrapper" :class="sliderWrapperClass">
                <div class="swiper-slide" :class="sliderSlideClass" v-for="slide in slides" :key="slide.id">
                    <div class="doujin-category-ranking">
                        <h3 class="doujin-category-ranking__ttl">{{slide.category}}</h3>
                        <ul class="doujin-category-ranking__list">
                            <li class="doujin-category-ranking__list__item" v-for="post in slide.posts" :key="post.id">
                                <div class="product product--doujin-row">
                                    <p class="product__rank">
                                        <img :src="'/images/prod-rank' + post.id + '.svg'" :alt="post.id" v-if="post.id < 4">
                                        <span v-else>{{post.id}}</span>
                                    </p>
                                    <a :href="post.uri" class="product__img">
                                        <div class="product__img__frame">
                                            <img src="/images/prod-frame3.jpg" alt="" class="product__img__bg">
                                            <img :data-src="post.imgSrc" src="/images/prod-frame3.jpg" alt="" class="product__img__main lazyload">
                                        </div>
                                        <div class="product__img__band" v-if="post.exclusive">
                                            <img data-src="/images/prod-band.svg" src="/images/prod-band-dummy.jpg" alt="" class="lazyload">
                                        </div>
                                    </a>
                                    <div class="product__main">
                                        <ul class="product__tags">
                                            <li class="product__tags__item" v-for="tag in post.tags" :key="tag">{{tag}}</li>
                                        </ul>
                                        <a :href="post.uri" class="product__name">{{post.name}}</a>
                                        <a :href="post.companyLink" class="product__company" v-if="post.companyLink">{{post.company}}</a>
                                        <span class="product__company" v-else></span>
                                        <p class="product__price">
                                            <span class="product__price__main" :class="{'product__price__main--stockout' : post.stockout}">{{post.price}}</span>
                                            <span class="product__price__original" v-if="post.priceOriginal && !post.stockout">{{post.priceOriginal}}</span>
                                            <span class="product__price__stockout" v-if="post.stockout">在庫切れ</span>
                                        </p>
                                        <p class="product__discount" v-html="post.discount" v-if="post.discount"></p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="doujin-category-ranking__btn">
                            <a :href="slide.link" class="btn1">もっと見る</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-pagination" :class="sliderPaginationClass"></div>
        </div>
    </div>
</template>

<script>
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

export default {
    props: {
        slides: {
            type: Array,
            required: true
        },
        sliderName: {
            type: String,
            required: true
        },
        sliderClass: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            slider: null,
            loading: false,
            loaded: false,
            error: false
        }
    },
    computed: {
        sliderContainerClass(){
            return `${this.sliderClass}-container`;
        },
        sliderMainClass(){
            return `${this.sliderName} ${this.sliderClass}`;
        },
        sliderWrapperClass(){
            return `${this.sliderClass}__wrapper`;
        },
        sliderSlideClass(){
            return `${this.sliderClass}__slide`;
        },
        sliderPaginationClass(){
            return `${this.sliderName}__pagination ${this.sliderClass}__pagination`;
        }
    },
    methods: {
        sliderInit(){
            this.slider = new Swiper(`.${this.sliderName}`, {
                spaceBetween: 40,
                slidesPerView: 1,
                breakpoints: {
                    576: {
                        spaceBetween: 40,
                        slidesPerView: 1,
                    },
                    1082: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1582: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    }
                },
                pagination: {
                    el: `.${this.sliderName}__pagination`,
                    clickable: true,
                },
            });
        },
        sliderUpdate(){
            this.slider.update();
        },
        sliderResetPosition(){
            this.slider.slideTo(0, 0);
        },
        setSlider(){
            this.sliderInit();
        },
        categoryColor(color){
            return 'product__category--' + color;
        },
        matchHeight(el){
            const targets = document.querySelector('.' + this.sliderName).querySelectorAll(el);
            let maxHeight = 0;
            for (let index = 0; index < targets.length; index++) {
                const target = targets[index];
                target.style.height = 'auto';
                if(target.clientHeight > maxHeight){
                    maxHeight = target.clientHeight;
                }
            }
            for (let index = 0; index < targets.length; index++) {
                const target = targets[index];
                target.style.height = maxHeight + 'px';
            }
        }
    },
    mounted(){
        this.setSlider();
        this.matchHeight('.product__name');
        this.matchHeight('.product__tags');
        this.matchHeight('.product__company');
        this.matchHeight('.product__price');
    },
    beforeUpdate(){
        const loadedImages = this.$el.querySelectorAll('.lazyloaded');
        for (let index = 0; index < loadedImages.length; index++) {
            const element = loadedImages[index];
            element.className = 'product__img__main lazyload';
        }
    },
    updated(){
        this.matchHeight('.product__name');
        this.matchHeight('.product__tags');
        this.matchHeight('.product__company');
        this.matchHeight('.product__price');
    }
}
</script>
