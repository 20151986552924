import MoveTo from 'moveto';
import { debounce } from 'throttle-debounce';
import { createApp } from 'vue';
import SliderRanking from '../components/SliderRanking.vue';
import { backfaceFixed } from '../plugins/backfaceFixed';
import audioPlayer from '../modules/audioPlayer';

if(document.getElementById('article-app')){
    const buyBox = {
        template: '#buy-box',
        props: {
            name: {
                type: String,
                required: true
            },
            checked: {
                type: Boolean,
                required: false
            },
            modelValue: {
                required: true
            },
            fixed: {
                type: Boolean,
                required: false
            },
            productStatus: {
                type: Number,
                required: true
            },
            originalPrice: {
                type: Number,
                required: true
            },
            actualPrice: {
                type: Number,
                required: true
            },
            point: {
                type: Number,
                required: true
            },
            pointPercent: {
                type: Number,
                required: true
            },
            stock: {
                type: Number,
                required: true
            },
            dateRelease: {
                type: String,
                required: true
            },
            dateOrder: {
                type: String,
                required: true
            }
        },
        data(){
            return {
                // point: 0
            }
        },
        emits: ['update:modelValue', 'change-point'],
        mounted(){
            
        },
        watch: {
            modelValue(value){
                const target = document.querySelector('input[type="radio"][value="' + value +'"]');
                const productStatus = Number(target.dataset.productStatus);
                const originalPrice = Number(target.dataset.originalPrice);
                const actualPrice = Number(target.dataset.actualPrice);
                const point = Number(target.dataset.pointValue);
                const pointPercent = Number(target.dataset.pointPercent);
                const stock = Number(target.dataset.stock);
                const dateRelease = String(target.dataset.dateRelease);
                const dateOrder = String(target.dataset.dateOrder);
                this.$emit('change-point', productStatus, originalPrice, actualPrice, point, pointPercent, stock, dateRelease, dateOrder);
            }
        }
    };
    const articleApp = createApp({
        data(){
            return {
                posts_related: posts_related,
                posts_checked: posts_checked,
                posts_recommend: posts_recommend,
                posts_favorite: posts_favorite,
                posts_maker: posts_maker,
                posts_series: posts_series,
                buyBoxRight: true,
                touchdevice: false,
                selectValue: undefined,
                scrollTriggerPoint: '',
                scrollTrigger: false,
                galleryModal: false,
                galleryModalMoveTo: null,
                galleryModalImageLoaded: false,
                extraModal: false,
                extraModalMoveTo: null,
                extraModalImageLoaded: false,
                tokutenModal: false,
                tokutenModalMoveTo: null,
                tokutenModalImageLoaded: false,
                movieModal: false,
                imageModal: false,
                productStatus: 1,
                originalPrice: 0,
                actualPrice: 0,
                point: 0,
                pointPercent: 0,
                stock: 0,
                dateRelease: '',
                dateOrder: '',
            }
        },
        components: {
            SliderRanking,
            buyBox
        },
        methods: {
            changePoint(productStatus, originalPrice, actualPrice, point, pointPercent, stock, dateRelease, dateOrder) {
                this.productStatus = productStatus;
                this.originalPrice = originalPrice;
                this.actualPrice = actualPrice;
                this.point = point;
                this.pointPercent = pointPercent;
                this.stock = stock;
                this.dateRelease = dateRelease;
                this.dateOrder = dateOrder;
            },
            toggleBuyBoxPosition(){
                const scrollQuantity = window.pageYOffset;
                if(scrollQuantity > this.scrollTriggerPoint){
                    if(!this.scrollTrigger){
                        console.log(document.querySelector('.buy-box').clientHeight);
                        this.scrollTrigger = true;
                    }
                }else{
                    if(this.scrollTrigger){
                        this.scrollTrigger = false;
                    }
                }
            },
            galleryModalOpen(target){
                this.galleryModal = true;
                const loadImages =  (callback) => {
                    const images = document.getElementById('modal-container--gallery').querySelector('.modal-gallery__wrapper').children;
                    for (let index = 0; index < images.length; index++) {
                        const image = images[index].firstChild;
                        image.classList.add('lazyload');
                        lazySizes.loader.unveil(image);
                    }
                    this.galleryModalImageLoaded = true;
                    callback();
                }
                const scrollToTarget = () => {
                setTimeout(() => {
                    this.galleryModalMoveTo.move(document.getElementById(target));
                }, 150);
                }
                if(this.galleryModalImageLoaded){
                    scrollToTarget();
                }else{
                    loadImages(() => {
                        scrollToTarget();
                    });
                }
                backfaceFixed(true);
            },
            galleryModalClose(){
                this.galleryModal = false;
                setTimeout(() => {
                    document.getElementById('modal-container--gallery').scrollTop = 0;
                    backfaceFixed(false);
                }, 300);
            },
            tokutenModalOpen(target){
                this.tokutenModal = true;
                const loadImages =  (callback) => {
                    const images = document.getElementById('modal-container--tokuten').querySelector('.modal-gallery__wrapper').children;
                    for (let index = 0; index < images.length; index++) {
                        const image = images[index].firstChild;
                        image.classList.add('lazyload');
                        lazySizes.loader.unveil(image);
                    }
                    this.tokutenModalImageLoaded = true;
                    callback();
                }
                const scrollToTarget = () => {
                setTimeout(() => {
                    this.tokutenModalMoveTo.move(document.getElementById(target));
                }, 150);
                }
                if(this.tokutenModalImageLoaded){
                    scrollToTarget();
                }else{
                    loadImages(() => {
                        scrollToTarget();
                    });
                }
                backfaceFixed(true);
            },
            tokutenModalClose(){
                this.tokutenModal = false;
                setTimeout(() => {
                    document.getElementById('modal-container--tokuten').scrollTop = 0;
                    backfaceFixed(false);
                }, 300);
            },
            extraModalOpen(target){
                this.extraModal = true;
                const loadImages =  (callback) => {
                    const images = document.getElementById('modal-container--extra').querySelector('.modal-gallery__wrapper').children;
                    for (let index = 0; index < images.length; index++) {
                        const image = images[index].firstChild;
                        image.classList.add('lazyload');
                        lazySizes.loader.unveil(image);
                    }
                    this.extraModalImageLoaded = true;
                    callback();
                }
                const scrollToTarget = () => {
                setTimeout(() => {
                    this.extraModalMoveTo.move(document.getElementById(target));
                }, 150);
                }
                if(this.extraModalImageLoaded){
                    scrollToTarget();
                }else{
                    loadImages(() => {
                        scrollToTarget();
                    });
                }
                backfaceFixed(true);
            },
            extraModalClose(){
                this.extraModal = false;
                setTimeout(() => {
                    document.getElementById('modal-container--extra').scrollTop = 0;
                    backfaceFixed(false);
                }, 300);
            },
            movieModalOpen(){
                this.movieModal = true;
                backfaceFixed(true);
            },
            movieModalClose(){
                this.movieModal = false;
                setTimeout(() => {
                    backfaceFixed(false);
                }, 300);
            },
            imageModalOpen(){
                this.imageModal = true;
                backfaceFixed(true);
            },
            imageModalClose(){
                this.imageModal = false;
                setTimeout(() => {
                    backfaceFixed(false);
                }, 300);
            }
        },
        mounted(){

            // console.log(document.querySelector('input[name="select"]:checked').dataset.pointValue);

            if(document.querySelector('input[name="select"]:checked')){
                this.productStatus = Number(document.querySelector('input[name="select"]:checked').dataset.productStatus);
                this.originalPrice = Number(document.querySelector('input[name="select"]:checked').dataset.originalPrice);
                this.actualPrice = Number(document.querySelector('input[name="select"]:checked').dataset.actualPrice);
                this.point = Number(document.querySelector('input[name="select"]:checked').dataset.pointValue);
                this.pointPercent = Number(document.querySelector('input[name="select"]:checked').dataset.pointPercent);
                this.stock = Number(document.querySelector('input[name="select"]:checked').dataset.stock);
                this.dateRelease = String(document.querySelector('input[name="select"]:checked').dataset.dateRelease);
                this.dateOrder = String(document.querySelector('input[name="select"]:checked').dataset.dateOrder);
            }

            this.galleryModalMoveTo = new MoveTo({
                duration: 500,
                container : document.getElementById('modal-container--gallery')
            });

            if(document.getElementById('modal-container--tokuten')){
                this.tokutenModalMoveTo = new MoveTo({
                    duration: 500,
                    container : document.getElementById('modal-container--tokuten')
                });
            }

            if(document.getElementById('modal-container--extra')){
                this.extraModalMoveTo = new MoveTo({
                    duration: 500,
                    container : document.getElementById('modal-container--extra')
                });
            }

            const _this = this;

            if(window.matchMedia('(min-width: 1200px)').matches){
                _this.buyBoxRight = true;
            }else{
                _this.buyBoxRight = false;
            }
            if(window.matchMedia('(min-width: 576px)').matches){
                _this.touchdevice = false;
            }else{
                _this.touchdevice = true;
            }
            if(_this.buyBoxRight){
                const clientRect = document.querySelector('.buy-box').getBoundingClientRect();
                const height = document.querySelector('.buy-box').offsetHeight;
                _this.scrollTriggerPoint = window.pageYOffset + clientRect.top + height;
                window.addEventListener('scroll', _this.toggleBuyBoxPosition);
            }
            window.addEventListener('resize', debounce(250, () =>{
                if(window.matchMedia('(min-width: 1200px)').matches){
                    if(!_this.buyBoxRight){
                        _this.buyBoxRight = true;
                    }
                }else{
                    if(_this.buyBoxRight){
                        _this.buyBoxRight = false;
                    }
                }
                if(window.matchMedia('(min-width: 576px)').matches){
                    if(_this.touchdevice){
                        _this.touchdevice = false;
                    }
                }else{
                    if(!_this.touchdevice){
                        _this.touchdevice = true;
                    }
                }
            }));

            if(document.querySelector('.audioPlayer')){
                document.querySelectorAll('.audioPlayer').forEach(audio => {
                    console.log(audio.getAttribute('id'));
                    const targerId = audio.getAttribute('id');
                    const playlist = audiolist[audio.dataset.playlist];
                    audioPlayer(targerId, playlist);
                });
            }

        },
        watch: {
            buyBoxRight(value){
                if(value){
                    if(this.scrollTriggerPoint !== ''){
                        window.addEventListener('scroll', this.toggleBuyBoxPosition);
                    }else{
                        setTimeout(() => {
                            const clientRect = document.querySelector('.buy-box').getBoundingClientRect();
                            const height = document.querySelector('.buy-box').offsetHeight;
                            this.scrollTriggerPoint = window.pageYOffset + clientRect.top + height;
                            // console.log(this.scrollTriggerPoint);
                            window.addEventListener('scroll', this.toggleBuyBoxPosition);
                        }, 100);
                    }
                }else{
                    window.removeEventListener('scroll', this.toggleBuyBoxPosition);
                }
            }
        }
    });
    articleApp.mount('#article-app');
}
